/* eslint-disable no-param-reassign */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCustomFields } from 'ducks/crm/customFields/selectors/base'
import {
  getChannelIdFromKey,
  isChannelCustomFieldKey,
  isChannelKey,
} from 'ducks/crm/channels/utils'
import { selectCurrentChannelsById } from 'ducks/channels/selectors'
import produce from 'immer'
import { getRawId } from 'util/globalId'
import {
  DATE,
  DROPDOWN,
  MULTI_SELECT,
  NUMBER,
} from 'ducks/crm/customFields/types'
import { selectIsCustomFieldsEnabled } from 'selectors/app/selectAccountPreferences'
import {
  containsDoesNotContainOperators,
  folderVariables,
  numberOperators,
  isOrIsNotOperator,
} from './data'

export const useFolderVariables = () => {
  const customFields = useSelector(selectCustomFields)
  const channelsById = useSelector(selectCurrentChannelsById)
  const isCustomFieldsEnabled = useSelector(selectIsCustomFieldsEnabled)

  const folderVariablesWithCustomFields = useMemo(
    () => {
      if (!isCustomFieldsEnabled) return folderVariables

      return produce(folderVariables, draft => {
        customFields.forEach(field => {
          if (!isChannelCustomFieldKey(field.key)) return

          const channelId = getRawId(getChannelIdFromKey(field.key))

          const channelName = channelsById[channelId]?.name
          if (isChannelKey(field.key) && !channelName) return

          const name = `${channelName || `All ${app.t('mailboxes')}`}: ${
            field.name
          }`
          const value = `CUSTOM_FIELD_${field.key}`

          const parameter = {
            name,
            value,
          }

          if ([NUMBER].includes(field.type)) {
            draft.param_operators[value] = [
              ...isOrIsNotOperator,
              ...numberOperators,
            ]
            draft.values[value] = {
              dataType: 'NUMBER',
            }
          } else if ([DATE].includes(field.type)) {
            draft.param_operators[value] = [
              ...isOrIsNotOperator,
              ...numberOperators,
            ]
            draft.values[value] = {
              dataType: 'DATE',
            }
          } else if ([DROPDOWN, MULTI_SELECT].includes(field.type)) {
            draft.param_operators[value] = [...containsDoesNotContainOperators]
            draft.values[value] = {
              dataType: 'CUSTOM_FIELDS',
              customFieldKey: field.key,
            }
          } else {
            // We dont support smart folders on text based custom fields
            return
          }
          draft.parameters.push(parameter)
        })
      })
    },
    [customFields, channelsById, isCustomFieldsEnabled]
  )
  return folderVariablesWithCustomFields
}
